<template>

    <div class="row justify-content-center">
        <div class="col-10" v-if="errortext != null">
            <div class="alert alert-danger mb-5 text-center" role="alert">
                {{errortext}}
            </div>
        </div>
        <div class="col-10 col-lg-3" style="text-align: center">
            <div class="mb-3">
                <label for="FormUsername" class="form-label">Benutzername</label>
                <input type="text" class="form-control" id="FormUsername" v-model="username">
            </div>
            <div class="mb-3">
                <label for="FormPassword" class="form-label">Passwort</label>
                <input type="password" class="form-control" id="FormPassword" v-model="password">
            </div>
            <div class="mb-3">
                <button type="button" class="btn btn-success" :disabled="username == '' || password == '' || loggingIn"
                    @click="login">Anmelden</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginForm',
    data: function () {
        return {
            username: '',
            password: '',
            errortext: null,
            loggingIn: false
        }
    },
    methods:
    {
            login: function () {
            this.errortext = null
            this.loggingIn = true
            let self = this
            fetch(this.$parent.url+"/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ Username: this.username, Password: this.password }),
            }).then((response) => {
                if (!response.ok) {
                    throw response
                }
                else {
                    return response.json();
                }
            }).then((data) => {
                //console.log(data)
                self.$parent.table = data.invoices
                self.$parent.token = data.token
                self.loggingIn = false
                //$cookies.set(keyName, value[, expires[, path[, domain[, secure[, sameSite]]]]])   //return this

                self.$cookies.set('token', data.token)
            }).catch((error) => {
                self.loggingIn = false

                if(error.status == 401)
                {
                    self.errortext = "Fehler! Benutzername und/oder Passwort falsch."
                }
                else
                {
                    self.errortext = "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut!"

                }
            });
        }
    }
}
</script>

<style>

</style>