<template>
    <div class="row justify-content-center">
        <div class="col-12" style="text-align: center">
            <h3 class="text-start mb-4">Ihre Dokumente</h3>

            <table class="table table-responsive table-bordered table-hover mb-5 mt-3">
                <thead class="table-light">
                    <tr>
                        <th>Rechnungsdatum</th>
                        <th>Rechnungsnummer</th>
                        <th class="d-none d-md-table-cell">Typ</th>
                        <th class="d-none d-lg-table-cell">Abrechnungszeitraum</th>
                        <th class="d-none d-md-table-cell">Bruttobetrag</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in tabledata" v-bind:key="row.id">
                        <td>{{
                            new Date(row.bdatum).toLocaleDateString("de-DE", {
                                year: 'numeric', month: '2-digit',
                                day: '2-digit'
                            })
                        }}</td>
                        <td>{{ row.rechnr }}</td>
                        <!--<td>{{ row.rechnr[0].toLowerCase() == 'g' ? 'Gutschrift' : 'Rechnung' }}</td>-->

                        <td class="d-none d-md-table-cell">
                            <span v-if="row.rechnr[0].toLowerCase() == 'g'"
                                class="badge text-bg-success">Gutschrift</span>
                            <span v-if="row.rechnr[0].toLowerCase() != 'g'"
                                class="badge text-bg-warning">Rechnung</span>
                        </td>

                        <td class="d-none d-lg-table-cell">{{
                            new Date(row.vdatum).toLocaleDateString("de-DE", {
                                year:
                                    'numeric', month: '2-digit', day: '2-digit'
                            })
                        }} - {{
    new
                            Date(row.bdatum).toLocaleDateString("de-DE", {
        year: 'numeric', month: '2-digit', day:
            '2-digit'
    })
}}</td>
                        <td class="d-none d-md-table-cell">{{
                            row.brutto.toLocaleString("de-DE", {
                                style: "currency",
                                currency: "EUR"
                            })
                        }}</td>
                        <td><a :href="this.$parent.url + '/download?id=' + row.id" target="_blank"><span
                                    id="viewinvoice" class="badge rounded-pill text-bg-primary">Anzeigen&nbsp;<i
                                        class="fa fa-search" aria-hidden="true"></i></span></a></td>

                    </tr>
                </tbody>
            </table>
            <nav>
                <ul class="pagination justify-content-end ">
                    <li class="page-item disabled"><a class="page-link" href="#">Zur&uuml;ck</a></li>
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <li class="page-item disabled"><a class="page-link" href="#">Vor</a></li>
                </ul>
            </nav>



        </div>
    </div>
</template>

<script>
export default {
    name: 'TableView',
    props: {
        tabledata: Object
    },
    data: function () {
        return {

        }
    },
    methods:
    {
    }
}
</script>

<style>
@import '../../node_modules/font-awesome';

#viewinvoice {
    cursor: pointer;
}

table tbody {
    font-size: 0.95em;
}

table tbody tr td {
    padding: 4px 4px 4px 4px !important;
}

.page-link {
    font-size: 0.85em !important;
}
</style>