import { createApp } from 'vue'
import App from './App.vue'
import '@popperjs/core'
import 'bootstrap'
import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }


createApp(App).use(VueCookies, { expires: '1d'}).mount('#app')
