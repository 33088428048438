<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container">
        <a class="navbar-brand" href="#"><img alt="Postcollect Logo" src="./assets/pc_logo2.jpg"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="table != null">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">&Uuml;bersicht</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#" @click="table = null">Logout</a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
    <div class="container" id="content">
      <LoginForm v-if="table == null" />
      <TableView v-if="table != null" :tabledata="table" />

    </div>
    <div class="fixed-bottom text-end mb-2"><a href="https://postcollect.de/index.php?page=impressum"
        target="_blank"><span class="me-2 badge text-bg-success">Impressum</span></a></div>

  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import LoginForm from './components/LoginForm.vue'
import TableView from './components/TableView.vue'
//import CookieConsent from 'cookie-consent-js'

export default {
  name: 'App',
  components: {
    LoginForm,
    TableView
  },
  data: function () {
    return {
      table: null,
      token: null,
      url: process.env.NODE_ENV == 'development' ? 'http://localhost:7902' : '/api'
    }
  },
  mounted() {
    
  }
}
</script>

<style>
@import "../node_modules/bootstrap";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #000;
  /*margin-top: 60px;*/
}

.navbar .container {
  box-shadow: 0px 15px 5px -15px rgba(0, 0, 0, 0.2);
}

#content {
  margin-top: 30px;
}
</style>
